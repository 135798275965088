// Entry point for the build script in your package.json
import "@hotwired/turbo-rails"
import "./controllers"

import Alpine from 'alpinejs'
import focus from '@alpinejs/focus'
 
Alpine.plugin(focus)

window.Alpine = Alpine
Alpine.start()

import posthog from 'posthog-js'

posthog.init('phc_jxyDaEd9NFYbr3k8n72PEvb6nJu1YAM6U0wrGuqh7nF',
    {
        api_host: 'https://us.i.posthog.com',
        person_profiles: 'identified_only' // or 'always' to create profiles for anonymous users as well
    }
)
